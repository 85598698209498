import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/layout";


const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: fles;
  justify-content: center;
  align-items: center;
`;


const NotFoundPage = () => {
  return (
    <Layout>
      <StyledWrapper>
        <h1>Page not found</h1>
      </StyledWrapper>
    </Layout>

  )
}

export default NotFoundPage
